import loadResource from '../../resource/loadResource';
import renderReal from "../../render/renderReal";
class ReplaceCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env;
        this.originalResourceId = null;
    }
    async do() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                if (!this.originalResourceId) {
                    this.originalResourceId = flowerObject.resource.id;
                }
                if (!this.env.resource[this.params.resourceId]) {
                    this.env.resource[this.params.resourceId] = await loadResource(this.params.resourceId);
                }
                const resource = this.env.resource[this.params.resourceId];
                flowerObject.changeResource(resource);
                flowerObject.clearReal();
                break;
            }
        }
        renderReal(this.env);
    }
    prev() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                flowerObject.changeResource(this.env.resource[this.originalResourceId]);
                flowerObject.clearReal();
                break;
            }
        }
        renderReal(this.env);
    }
}

export default ReplaceCommand;