<template>
  <div class="act_layout">
    <van-slider
      v-model="bindingY"
      @input="handleInput"
      @change="renderRealScene"
      bar-height="4px"
      inactive-color="#999"
      active-color="#6e9f21"
      :min="-200"
      :max="200"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      bindingY: 0,
      id: null,
    };
  },
  methods: {
    init(item) {
      this.id = item.id;
      this.bindingY = item.bindingY;
    },
    handleInput() {
      if (!this.id) {
        return;
      }
      this.$bus.$emit("setBindingY", {
        id: this.id,
        value: this.bindingY,
      });
    },
    renderRealScene() {
      this.$bus.$emit("renderRealScene");
    },
  },
};
</script>

<style>
</style>