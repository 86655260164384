import renderModel from "../../render/renderModel";
import renderReal from "../../render/renderReal";

class SetBindingTypeCommand {
    constructor(show, env) {
        this.show = show;
        this.env = env
    }
    do() {
        this.env.params.render.touchHelper = this.show;
        renderModel(this.env);
        renderReal(this.env);
    }
}

export default SetBindingTypeCommand;