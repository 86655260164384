import loadResource from '../../resource/loadResource';
import FlowerObject from '../object/FlowerObject';
import ContainerObject from '../object/ContainerObject';
import renderReal from '../../render/renderReal';
import ReomveCommand from './ReomveCommand'
class AddItemCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env;
        this.id = null;
        this.prevNeedAdd = false;
    }
    async do() {
        //查询资源缓存若有则是直接使用，若没有则加载网络资源
        if (!this.env.resource[this.params.resourceId]) {
            this.env.resource[this.params.resourceId] = await loadResource(this.params.resourceId);
        }
        const resource = this.env.resource[this.params.resourceId];
        if (resource.type === 1) {
            const flowerObject = new FlowerObject({
                resource,
                id: this.params.id || this.id,
                env: this.env,
                position: this.params.position,
                params: this.params.params
            })
            this.env.objects.flowers.push(flowerObject);
            this.id = flowerObject.id;
        } else {
            let sourceContainer = this.env.objects.container;
            if (sourceContainer) {
                this.id = sourceContainer.id;
                sourceContainer.remove();
                this.prevNeedAdd = true;
            }
            const containerObject = new ContainerObject({
                resource,
                env: this.env,
                id: this.params.id || this.id,
                params: this.params.params
            })
            this.env.objects.container = containerObject;
            this.id = containerObject.id;
            //添加容器
            if (this.env.listener['container-add']) {
                this.env.listener['container-add']({
                    id: this.id,
                    params: containerObject.params
                });
            }

        }
        renderReal(this.env);

        return this.id;
    }
    prev() {
        new ReomveCommand(this.id, this.env).do();
        if (this.prevNeedAdd) {
            new AddItemCommand({ ...this.params, id: this.id }, this.env).do()
        }
    }
}

export default AddItemCommand;