import renderModel from "../../render/renderModel";
import renderReal from "../../render/renderReal";

class SetBindingTypeCommand {
    constructor(type, env) {
        this.type = type;
        this.env = env
    }
    do() {
        this.env.params.render.bindingType = this.type;
        renderModel(this.env);
        for (let flowerObject of this.env.objects.flowers) {
            flowerObject.updateModel();
        }
        renderReal(this.env);
    }
}

export default SetBindingTypeCommand;