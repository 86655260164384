
function getPointsArr(params) {
    let { position, bindingType, bindingY } = params;
    let curve = null;
    let pointsArr = [];
    if (bindingType === 'westStyle') {
        //标准比例
        const standardRate = (0 - bindingY) / (position.y - bindingY);

        curve = new THREE.LineCurve3(
            new THREE.Vector3(position.x, position.y, position.z),
            new THREE.Vector3(standardRate * position.x, 0, standardRate * position.z)
        );
    } else {
        curve = new THREE.QuadraticBezierCurve3(
            new THREE.Vector3(position.x, position.y, position.z),
            new THREE.Vector3(0, bindingY, 0),
            new THREE.Vector3(0, 0, 0)
        );
    }
    for (let point of curve.getPoints(128)) {
        pointsArr.push(point.x, point.y, point.z);
    }
    return pointsArr;
}

export default getPointsArr