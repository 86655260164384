/*
 * @Author: your name
 * @Date: 2022-01-26 09:44:26
 * @LastEditTime: 2022-02-10 17:42:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \front-wx\src\ajax\ajax.js
 */
import Vue from "vue";
import axios from "axios";
import { Toast } from 'vant';
// let baseURL = 'https://api.lvshuhualv.com/';
let baseURL = 'https://test-api.lvshuhualv.com/';
if (process.env.NODE_ENV === 'development') {
    baseURL = 'https://test-api.lvshuhualv.com/';
}
let ajax = axios.create({
    // baseURL: isDev ? "//api.flowersky.cc/" : "https://api.flowersky.cc/"
    baseURL,
    withCredentials: true
});
ajax.interceptors.request.use((config) => {
    return config
})

// 添加响应拦截器
ajax.interceptors.response.use(
    (res) => {
        // 对响应数据做点什么
        const code = res.data.code || 200;
        if (code === 401) {
        } else if (code !== 200) {
            if (code === 403) {
                return Promise.reject(res.data);
            }
            Toast(res.data.msg);
            return Promise.reject(res.data);
        }
        return res.data;
    },
    (error) => {
    }
);

Vue.prototype.$ajax = ajax;
Vue.prototype.$axios = axios;