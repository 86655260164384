import { formatImage } from '../../utils/index';
import { reomveObjectFromScene } from '../../utils/index'
class ContainerObject {
    constructor(initData) {
        let { resource, env, id, params } = initData;
        //id
        this.id = id || 'container_' + new Date().getTime();
        //资源
        this.resource = resource;
        //环境
        this.env = env;
        //物体实例
        this.mesh = null;
        //配置项
        this.params = params || {
            //大小
            size: 1
        }
        //初始化
        this.init()
    }

    //初始化
    init() {
        for (let data of JSON.parse(JSON.stringify(this.resource.detail))) {
            let points = data.points,
                line = [];
            points.reverse();
            for (var j = 0; j < points.length; j += 20) {
                line.push(
                    new THREE.Vector2(points[j] / 16, (j - 0) / 16)
                );
            }
            var mesh = new THREE.Mesh(new THREE.LatheGeometry(line, 60), new THREE.MeshPhysicalMaterial({
                side: 2,
                map: new THREE.TextureLoader().load(formatImage(data.map)),
            }));
            this.mesh = mesh;
            this.env.three.scene.add(mesh);
        }
        this.updateModel();
    }
    //更新模型
    updateModel() {
        this.mesh.scale.x = this.params.size;
        this.mesh.scale.y = this.params.size;
        this.mesh.scale.z = this.params.size;
    }
    //移除
    remove() {
        reomveObjectFromScene(this.env.three.scene, this.mesh);
        this.env.objects.container = null;
    }
}

export default ContainerObject;