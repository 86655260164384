<template>
  <div id="core"></div>
</template>

<script>
let editor;
import { Editor } from "../../../3D-editor/index";
import { login } from "@/plugins/utils.js";
export default {
  mounted() {
    editor = new Editor({ dom: "core", plat: "mobile", readOnly: true });
    login(() => {
      if (this.$route.query.id) {
        this.$ajax
          .get("/product/getById", {
            params: {
              id: this.$route.query.id,
              type: 1,
            },
          })
          .then((res) => {
            editor.load(JSON.parse(res.data.dataJson));
          });
      }
    },this);
  },
};
</script>

<style lang="scss" scoped>
#core {
  width: 100vw;
  height: 100vh;
}
</style>