
class SetZoomCommand {
    constructor(val, env) {
        this.val = val;
        this.env = env
    }
    do() {
            this.env.three.viewController.object.zoom = this.val;
            this.env.three.viewController.object.updateProjectionMatrix();
    }
}

export default SetZoomCommand;