<template>
  <div>
    <div id="core"></div>
    <van-dialog v-model="nameTip" title="作品名称" @confirm="save">
      <van-field v-model="name" label="" />
    </van-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Editor } from "../../../../3D-editor/index";
import { base64ToFile, uploadImage } from "@/plugins/utils.js";
import { Toast } from "vant";
let editor;
export default {
  data() {
    return {
      id: null,
      name: null,
      ossData: null,
      describe: null,
      nameTip: false,
    };
  },
  mounted() {
    editor = new Editor({ dom: "core", plat: "mobile" });
    this.getOSSKey();
    //添加bus线事件
    this.addBusEvent();
    //添加编辑器事件
    this.addEditorEvent();
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData(this.id);
    }
  },
  methods: {
    getOSSKey() {
      this.$ajax.post("/user/oss/getKey?pathName=common").then(async (res) => {
        this.ossData = res.data;
      });
    },
    getData(id) {
      this.$ajax
        .get("/product/getById", {
          params: {
            id,
            type: 1,
          },
        })
        .then((res) => {
          this.name = res.data.name;
          this.describe = res.data.describe;
          let dataJson = JSON.parse(res.data.dataJson);
          this.$bus.$emit("setDefaultAngle", dataJson.angle);
          editor.load(dataJson);
        });
    },
    addBusEvent() {
      //添加
      this.$bus.$on("addItem", (id) => {
        editor.addItem(id);
      });
      //弯曲
      this.$bus.$on("setBindingY", (data) => {
        editor.setBindingY(data);
      });
      //大小
      this.$bus.$on("setSize", (data) => {
        editor.setSize(data);
      });
      //旋转
      this.$bus.$on("rotate", (p) => {
        let r = editor.rotate(p);
        this.$bus.$emit("rotate-result", r);
      });
      //移除
      this.$bus.$on("remove", (id) => {
        editor.remove(id);
      });

      //清除
      this.$bus.$on("clear", (type) => {
        editor.clear(type);
      });

      //复制
      this.$bus.$on("copy", (id) => {
        editor.copy(id);
      });

      //触摸点
      this.$bus.$on("setTouchHelper", (val) => {
        editor.setTouchHelper(val);
      });

      //插法
      this.$bus.$on("setBindingType", (type) => {
        editor.setBindingType(type);
      });

      //角度
      this.$bus.$on("setAngle", (val) => {
        editor.setAngle(val);
      });

      //后退
      this.$bus.$on("revoke", () => {
        editor.revoke();
      });

      //前进
      this.$bus.$on("redo", () => {
        editor.redo();
      });

      //插法
      this.$bus.$on("setContainerSize", (val) => {
        editor.setContainerSize(val);
      });

      //控制剑山
      this.$bus.$on("showJsContainer", (val) => {
        editor.showJsContainer(val);
      });

      //保存
      this.$bus.$on("save", (type) => {
        this.save(type);
      });

      //渲染
      this.$bus.$on("renderRealScene", () => {
        editor.renderRealScene();
      });
    },
    addEditorEvent() {
      editor.addEventListener("flower-choose", (val) => {
        this.$bus.$emit("flower-choose", val);
      });

      editor.addEventListener("camera-change", (val) => {
        this.$bus.$emit("camera-change", val);
      });

      editor.addEventListener("step", (val) => {
        this.$bus.$emit("step-change", val);
      });

      editor.addEventListener("container-add", (val) => {
        this.$bus.$emit("container-add", val);
      });
    },
    save(type) {
      let saveData = editor.save();
      if (
        !saveData.jsonData.objects.flowers.length &&
        !saveData.jsonData.objects.container
      ) {
        Toast("请先编辑后再保存");
        return;
      }
      if (!this.id && !this.name) {
        Toast("请输入作品名称");
        this.nameTip = true;
        return;
      }

      const useMaterials = saveData.jsonData.objects.flowers.reduce(
        (obj, item) => {
          let find = obj.find((i) => i.materialId === item.resourceId);
          let _d = {
            materialId: item.resourceId,
            materialName: item.resourceName,
            count: 1,
          };
          find ? find.count++ : obj.push(_d);
          return obj;
        },
        []
      );
      let file = base64ToFile(saveData.base64, new Date().getTime() + ".png");
      uploadImage(
        {
          file,
        },
        this.ossData
      ).then((res) => {
        this.$ajax
          .post("/product/save", {
            id: this.id,
            dataJson: JSON.stringify(saveData.jsonData),
            source: 1,
            name: this.name,
            coverImage: res.addr,
            useMaterials,
            describe: this.describe,
          })
          .then((res) => {
            Toast("保存成功");
            this.id=null;
            this.name=null;
            editor.clear();
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#core {
  width: 100vw;
  height: 100vh;
}
</style>