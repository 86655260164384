
class SetBindingYCommand {
    constructor(params, env) {
        this.params = params;
        this.env = env
    }
    do() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.params.id === flowerObject.id) {
                flowerObject.clearReal();
                flowerObject.params.bindingY = this.params.value;
                flowerObject.updateModel();
                break;
            }
        }
    }
}

export default SetBindingYCommand;