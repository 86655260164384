import AddItemCommand from "./AddItemCommand";
import ReomveCommand from './ReomveCommand'
class CopyCommand {
    constructor(id, env) {
        this.id = id;
        this.env = env;
        this.newId = null;
    }
    async do() {
        for (let flowerObject of this.env.objects.flowers) {
            if (this.id === flowerObject.id) {
                this.newId = await new AddItemCommand({
                    resourceId: flowerObject.resource.id,
                    position: flowerObject.modelMesh.flowerMesh.position,
                    params: JSON.parse(JSON.stringify(flowerObject.params))
                }, this.env).do();
                break;
            }
        }
    }
    prev() {
        new ReomveCommand(this.newId, this.env).do();
    }
}

export default CopyCommand;