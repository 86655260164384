//查看最接近哪个
function closeTo(arr, num) {
    var newArr = [];
    arr.map(function (x) {
        newArr.push(Math.abs(x - num));
    });
    var index = newArr.indexOf(Math.min.apply(null, newArr));
    return arr[index];
}

//图片处理
function formatImage(src) {
    return '//lvshuhualv.oss-cn-hangzhou.aliyuncs.com/' + src;
}

//删除
function reomveObjectFromScene(scene, object) {
    scene.remove(object);
    object.geometry.dispose();
    if (object.material.map) {
        object.material.map.dispose();
    }
    object.material.dispose();
    object = null;
}

//选中花
function selectFlower(event) {
    let pointer = new THREE.Vector2(),
        ray = new THREE.Raycaster();
    let objects = [];
    for (let obj of this.objects.flowers) {
        objects.push(obj.modelMesh.flowerMesh);
    }
    pointer.x = (event.offsetX / this.params.dom.clientWidth) * 2 - 1;
    pointer.y = -(event.offsetY / this.params.dom.clientHeight) * 2 + 1;
    ray.setFromCamera(pointer, this.three.camera);

    const raycasterObjs = ray.intersectObjects(objects);
    if (raycasterObjs.length) {
        let activeObject = raycasterObjs[0].object;
        if (activeObject !== this.three.objController.object) {
            this.three.objController.attach(activeObject, event);

        }
    }
}

export { closeTo, formatImage, reomveObjectFromScene, selectFlower };