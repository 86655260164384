import { BINDING_DEFAULT_Y, CAMERA_LOOKAT_Y } from '../variable/constant'
import { closeTo } from '../utils/index'
import createStem from '../line/createStem'
function renderReal(env) {
    for (let flowerObject of env.objects.flowers) {
        if (flowerObject.realMesh.flowerMesh) {
            continue;
        }
        flowerObject.modelMesh.flowerMesh.visible = false;
        flowerObject.modelMesh.stemMesh.visible = false;
        //比例
        const rate = flowerObject.params.size * 0.15;
        //角度信息
        const angles = flowerObject.resource.detail.angles;
        //困扎高度
        const bindingY = BINDING_DEFAULT_Y + flowerObject.params.bindingY;
        //花头位置
        const flowerPosition = flowerObject.modelMesh.flowerMesh.position;

        //花头到困扎点的向量
        const flowerToBindingVec3 = new THREE.Vector3(
            flowerPosition.x - 0,
            flowerPosition.y - bindingY,
            flowerPosition.z - 0
        );
        //视角方向
        const viewerPosition = env.three.viewController.object.position;
        //计算花与视角间的夹角
        let viewerToFlowerAngle = THREE.MathUtils.radToDeg(
            flowerToBindingVec3.angleTo(viewerPosition)
        );

        //取锐角
        if (viewerToFlowerAngle > 90) {
            viewerToFlowerAngle = 180 - viewerToFlowerAngle;
        }

        //资源中所有的角度
        let angleArr = [];
        for (let angle in angles) {
            angleArr.push(angle - 0);
        }

        //找出与图片最接近的角度
        const closestAngle = closeTo(angleArr, viewerToFlowerAngle);
        //匹配的信息
        const mateInfo = angles[closestAngle][flowerObject.params.faceIndex - 1];
        //放大缩小后的长宽
        const scaleSize = {
            width: rate / 1 * mateInfo.width,
            height: rate / 1 * mateInfo.height
        }
        //创建花模型
        const flowerMesh = new THREE.Mesh(new THREE.PlaneGeometry(
            scaleSize.width / 2,
            scaleSize.height / 2
        ), new THREE.MeshBasicMaterial({
            map: mateInfo.texture,
            transparent: true,
            depthWrite: false,
            opacity: 1,
        }));

        //图片朝向自身
        flowerMesh.lookAt(viewerPosition);

        //将图片移动到位置
        flowerMesh.position.set(flowerPosition.x, flowerPosition.y, flowerPosition.z);

        const stemX = ((-(scaleSize.width / 2) + mateInfo.points.stem.x * rate)) / 2;
        const centerX = ((-(scaleSize.width / 2) + mateInfo.points.center.x * rate)) / 2;
        const stemY = (scaleSize.height / 2 - mateInfo.points.stem.y * rate) / 2;
        const centerY = (scaleSize.height / 2 - mateInfo.points.center.y * rate) / 2;

        //计算出现在的花向量
        let nowStem = new THREE.Vector3(stemX, stemY, 0)
            .applyEuler(flowerMesh.rotation)
            .add(flowerPosition);
        let nowCenter = new THREE.Vector3(centerX, centerY, 0)
            .applyEuler(flowerMesh.rotation)
            .add(flowerPosition);
        nowCenter.sub(nowStem);

        //找到镜头面
        let face = new THREE.Vector3().copy(viewerPosition);
        face.y = face.y - CAMERA_LOOKAT_Y;

        //投影到镜头面上
        flowerToBindingVec3.projectOnPlane(face);
        nowCenter.projectOnPlane(face);
        let flowerToCenterAngle = flowerToBindingVec3.angleTo(nowCenter);
        //看是顺时针还是逆时针
        nowCenter.cross(flowerToBindingVec3);
        if (nowCenter.z < 0) {
            flowerToCenterAngle = -flowerToCenterAngle;
        }

        //将花束图片以摄像机向量为中心进行旋转
        if (!(false && angle < 30)) {
            flowerMesh.rotateOnAxis(
                new THREE.Vector3(0, 0, viewerPosition.z > 0 ? 1 : -1),
                flowerToCenterAngle
            );
        }

        //重新获取值
        nowStem = new THREE.Vector3(stemX, stemY, 0)
            .applyEuler(flowerMesh.rotation)
            .add(flowerPosition);
        nowCenter = new THREE.Vector3(centerX, centerY, 0)
            .applyEuler(flowerMesh.rotation)
            .add(flowerPosition);

        const originTranslateAxis = new THREE.Vector3()
            .copy(flowerPosition)
            .sub(nowCenter);

        //计算花束图片距离中心的偏移量
        const translateAxis = new THREE.Vector3(centerX, centerY, 0);
        const distance = translateAxis.length();

        //根据偏移量进行偏移
        flowerMesh.translateOnAxis(translateAxis.normalize(), -distance);
        //添加进入场景
        env.three.scene.add(flowerMesh);
        flowerObject.realMesh.flowerMesh = flowerMesh;

        //根据移动点生成根部
        let flowerRealStem = createStem({
            position: nowStem.add(originTranslateAxis),
            bindingType: env.params.render.bindingType,
            bindingY,
            dom: env.params.dom,
            materialOption: {
                color: flowerObject.resource.detail.stem.color,
                linewidth: flowerObject.resource.detail.stem.radiusStem * 2
            }
        })

        //场景中添加真实根
        env.three.scene.add(flowerRealStem);
        flowerObject.realMesh.stemMesh = flowerRealStem;
        //设置触摸点
        flowerObject.modelMesh.touchHelperMesh.position.set(flowerPosition.x, flowerPosition.y, flowerPosition.z);
        flowerObject.modelMesh.touchHelperMesh.visible = env.params.render.touchHelper;
    }
    env.three.status = 'real'
}

export default renderReal;