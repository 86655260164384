<template>
  <div class="act_layout">
    <van-slider
      v-model="size"
      @input="handleInput"
      @change="renderRealScene"
      :min="0.5"
      :max="2"
      :step="0.1"
      bar-height="4px"
      inactive-color="#999"
      active-color="#6e9f21"
    />
    <van-stepper
      class="stepper"
      :disable-minus="false"
      v-model="rAngle"
      theme="round"
      button-size="22"
      disable-input
      :min="-20"
      @plus="turn(1)"
      @minus="turn(-1)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      size: 1,
      id: "",
      rAngle: 1,
    };
  },
  methods: {
    turn(type) {
      this.$bus.$emit("rotate", {
        val: type,
        id: this.id,
      });
    },
    init(item) {
      this.rAngle = item.faceIndex;
      this.id = item.id;
      this.size = item.size;
    },
    handleInput() {
      if (!this.id) {
        return;
      }
      this.$bus.$emit("setSize", {
        id: this.id,
        value: this.size,
      });
    },
    renderRealScene() {
      this.$bus.$emit("renderRealScene");
    },
  },
  created() {
    this.$bus.$on("rotate-result", (val) => {
      this.rAngle = val;
    });
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  text-align: center;
  padding-top: rem(30);
}
</style>
<style lang="scss">
.van-stepper--round .van-stepper__plus {
  background-color: #6e9f21;
}
.van-stepper--round .van-stepper__minus {
  color: #6e9f21;
  border-color: #6e9f21;
}
.stepper .van-stepper__input{
  font-size: rem(35);
}
</style>