<template>
  <div class="act_layout">
    <van-slider
      v-model="size"
      @input="handleInput"
      :min="0.5"
      :max="2"
      :step="0.1"
      bar-height="4px"
      inactive-color="#999"
      active-color="#6e9f21"
    />
    <div class="lv_flexalign_center">
      <div
        class="act_button"
        :class="{ actived: actived === 1 }"
        @click="
          actived = 1;
          showJsContainer(true);
        "
      >
        开启
      </div>
      <div
        class="act_button"
        :class="{ actived: actived === 2 }"
        @click="
          actived = 2;
          showJsContainer(false);
        "
      >
        关闭
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      size: 1,
      actived: 1,
    };
  },
  methods: {
    handleInput() {
      this.$bus.$emit("setSize", {
        id: "jianshan",
        value: this.size,
      });
    },
    showJsContainer(val) {
      this.$bus.$emit("showJsContainer", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.lv_flexalign_center {
  margin-top: rem(35);
}
</style>
