<template>
  <div class="lv_flexalign_center act_layout">
    <div @click="remove" class="act_button">删除选中</div>
    <div @click="clear" class="act_button">全部删除</div>
  </div>
</template>

<script>
export default {
  props: ["selectedMaterial"],
  methods: {
    remove() {
      if (this.selectedMaterial) {
        this.$bus.$emit("remove", this.selectedMaterial.id);
      }
    },
    clear() {
      this.$bus.$emit("clear", "flower");
    },
  },
};
</script>