<template>
  <div>
    <topController />
    <bottomController />
    <editor />
  </div>
</template>

<script>
import topController from "./components/topController.vue";
import bottomController from "./components/bottomController.vue";
import editor from "./components/editor.vue";
import { login } from "@/plugins/utils.js";
export default {
  components: {
    topController,
    bottomController,
    editor,
  },
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    login(() => {
      this.loaded = true;
    }, this);
  },
};
</script>

<style>
</style>