import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import getPointsArr from './getPointsArr'

function createStem(params) {
    let { materialOption, dom } = params;
    let lineGeometry = new LineGeometry();
    lineGeometry.setPositions(getPointsArr(params));
    let lineMaterial = new LineMaterial(materialOption);
    lineMaterial.resolution.set(dom.clientWidth, dom.clientHeight);
    return new Line2(lineGeometry, lineMaterial);
}

export default createStem