<template>
  <div class="bottom_controller">
    <div v-show="actActived">
      <div class="act">
        <div
          v-for="(item, index) in acts"
          :key="index"
          :class="{ actived: index === actived }"
          @click="handleClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="act_detail lv_flexalign_center">
        <winding v-show="actived === 0" ref="winding" />
        <flower-head v-show="actived === 1" ref="flowerHead" />
        <remove v-show="actived === 2" :selectedMaterial="selectedMaterial" />
        <container v-show="actived === 5" />
        <jianshan v-show="actived === 6" />
        <touch v-show="actived === 7" />
        <bindType v-show="actived === 8" />
        <angle v-show="actived === 9" />
      </div>
    </div>
    <div class="bottom_act_text lv_flexalign_center">
      <div @click="wxReturn">返回</div>
      <div class="lv_flex">
        <div @click="$refs.choosePop.open()">花材</div>
        <div
          :class="{ act_text_actived: actActived }"
          @click="actActived = !actActived"
        >
          调整
        </div>
      </div>
      <div @click="save">保存</div>
    </div>
    <choosePop ref="choosePop" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import choosePop from "@/components/material/choosePop";
import winding from "@/components/act/winding";
import flowerHead from "@/components/act/flowerHead";
import remove from "@/components/act/remove";
import touch from "@/components/act/touch";
import bindType from "@/components/act/bindType";
import angle from "@/components/act/angle";
import container from "@/components/act/container";
import jianshan from "@/components/act/jianshan.vue";
export default {
  components: {
    winding,
    flowerHead,
    remove,
    touch,
    bindType,
    angle,
    container,
    jianshan,
    choosePop,
  },
  data() {
    return {
      actActived: false,
      selectedMaterial: null,
      actived: 0,
      acts: [
        "弯曲",
        "花头",
        "删除",
        "复制",
        "替换",
        "容器",
        "剑山",
        "焦点",
        "插法",
        "角度",
      ],
    };
  },
  created() {
    this.$bus.$on("flower-choose", (val) => {
      this.selectedMaterial = val;
      if (!val) {
        return;
      }
      this.$refs.winding.init(val);
      this.$refs.flowerHead.init(val);
    });
  },
  methods: {
    wxReturn() {
      wx.miniProgram.navigateBack({});
    },
    handleClick(index) {
      this.actived = index;
      if (index === 3) {
        this.$bus.$emit("copy", this.selectedMaterial.id);
      }
    },
    save() {
      this.$bus.$emit("save", "practice");
    },
  },
};
</script>
<style lang="scss">
.act_button {
  flex: 1;
  line-height: rem(40);
  border: 1px solid #dcdcdc;
  border-radius: rem(14);
  text-align: center;
  font-size: rem(24);
  background: #fff;
}
.act_button + .act_button {
  margin-left: rem(20);
}
.actived {
  background: #e8efd5;
}
</style>
<style lang="scss" scoped>
.act_text_actived {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: rem(20);
    height: rem(5);
    background: #6e9f21;
    left: rem(18);
    bottom: rem(-10);
  }
}
.bottom_controller {
  position: fixed;
  bottom: 0;
  width: 100vw;
  > .lv_flexalign_center {
    justify-content: space-between;
    height: rem(100);
    padding: 0 rem(40);
    font-size: rem(30);
    font-weight: bold;
    .lv_flex {
      font-size: rem(26);
      font-weight: normal;
      > div + div {
        margin-left: rem(65);
      }
    }
  }
  .act {
    white-space: nowrap;
    overflow-x: auto;
    padding: 0 rem(340);
    > div {
      line-height: rem(70);
      width: rem(70);
      font-size: rem(24);
      background: #fff;
      border-radius: 50%;
      border: 1px solid #f1f1f1;
      text-align: center;
      display: inline-block;
      + div {
        margin-left: rem(20);
      }
    }
    .actived {
      background: #e8efd5;
      color: #6e9f21;
    }
  }
  .act_detail {
    height: rem(150);
    > div {
      width: 100vw;
    }
  }
  .act_layout {
    padding: 0 rem(40);
  }
  .bottom_act_text {
    background: #fff;
  }
}
</style>