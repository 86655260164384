import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import {
    CAMERA_LOOKAT_Y,
    CAMERA_RADIUS
} from '../variable/constant';
import {
    CommandManager,
    AddItemCommand,
    SetBindingTypeCommand,
    SetTouchHelperCommand,
    SetBindingYCommand,
    SetSizeCommand,
    ReomveCommand,
    CopyCommand,
    ReplaceCommand,
    RotateCommand,
    SetZoomCommand,
    SetAngleCommand,
    MoveCommand,
    ClearCommand,
    CreateImageCommand
} from '../class/index.js'
import { TransformControls as pcObjectControl } from '../three/pcObjectControl';
import { TransformControls as mobileObjectControl } from '../three/mobileObjectControl';
import renderReal from '../render/renderReal';
import renderModel from '../render/renderModel';
import { selectFlower } from '../utils/index'
//将three设置到window中方便使用
window.THREE = THREE;

class Editor {
    constructor(config) {
        let { dom, plat, readOnly } = config;
        /* 初始化内置字段 */

        //初始化设置
        this.params = {
            //dom
            dom: typeof dom === 'string' ? document.getElementById(dom) : dom,
            //平台 pc 电脑鼠标 mobile 移动触摸
            plat: plat || 'pc',
            //是否只读
            readOnly: readOnly || false,
            //渲染相关
            render: {
                //放大倍数
                zoom: 1,
                //困扎方式   chineseStyle 中式 westStyle西式
                bindingType: 'chineseStyle',
                //显示触点
                touchHelper: false
            }
        };

        //three相关对象
        this.three = {
            //摄像机
            camera: null,
            //视角控制器
            viewController: null,
            //场景
            scene: null,
            //渲染器 
            renderer: null,
            //物体控制器
            objController: null,
            //状态  model 模型 real 真实
            status: ''
        };

        //物体相关对象
        this.objects = {
            //花朵（主要实例）
            flowers: [],
            //普通容器
            container: null,
            //剑山容器
            jsContainer: null,
            //光线（用于各类容器）
            lights: null,
            //辅助线
            supportLine: null
        }

        //用于储存移动位置
        this.tempPositions = [];

        //监听器对象
        this.listener = {};

        //资源栈
        this.resource = {};

        //渲染
        this.render = () => {
            this.three.renderer.render(this.three.scene, this.three.camera);
        };

        //命令管理器
        this.commandManager = new CommandManager(this);

        //帧渲染
        this.frameRender = () => {
            this.three.viewController.update();
            this.render();
            requestAnimationFrame(this.frameRender);
        };

        //初始化场景
        this.initScene();

        //初始化物体
        this.initObject();

        //初始化监听器
        this.initListener();
    }

    //初始化场景
    initScene() {
        //创建场景
        this.three.scene = new THREE.Scene();
        //创建渲染器
        this.three.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
        });
        //设置渲染像素
        this.three.renderer.setPixelRatio(window.devicePixelRatio);
        //设置渲染高宽
        this.three.renderer.setSize(
            this.params.dom.clientWidth,
            this.params.dom.clientHeight
        );
        //编辑器背景透明
        this.three.renderer.setClearColor(0xffffff, 0);


        //创建摄像机
        this.three.camera = new THREE.PerspectiveCamera(
            55,
            this.params.dom.clientWidth / this.params.dom.clientHeight,
            1,
            4400
        );
        this.three.camera.position.set(0, CAMERA_LOOKAT_Y, -CAMERA_RADIUS);

        //创建视角控制器
        this.three.viewController = new OrbitControls(
            this.three.camera,
            this.three.renderer.domElement
        );
        //设置视觉中心
        this.three.viewController.target = new THREE.Vector3(
            0,
            CAMERA_LOOKAT_Y,
            0
        );
        //限制最小距离
        this.three.viewController.minDistance = 100;
        //限制最大距离
        this.three.viewController.maxDistance = CAMERA_RADIUS;
        //限制最低视角高度
        this.three.viewController.maxPolarAngle = Math.PI / 1.75;
        //禁止平移缩放
        this.three.viewController.enablePan = false;
        this.three.viewController.enableZoom = false;




        //创建物体控制器
        var ObjectControl = this.params.plat === 'pc'
            ? pcObjectControl
            : mobileObjectControl;
        this.three.objController = new ObjectControl(
            this.three.camera,
            this.three.renderer.domElement
        );
        this.three.objController.transparent = 1;
        this.three.objController.addEventListener('change', this.render);
        this.three.objController.enabled = !this.params.readOnly;
        this.three.scene.add(this.three.objController);


        //插入dom
        this.params.dom.appendChild(this.three.renderer.domElement);

        //帧渲染
        this.frameRender();

    }

    //初始化监听器
    initListener() {
        //监听尺寸改变时进行相对应的调整
        window.addEventListener('resize', () => {
            this.three.camera.updateProjectionMatrix();
            this.three.camera.aspect =
                this.params.dom.clientWidth / this.params.dom.clientHeight;
            this.three.renderer.setSize(
                this.params.dom.clientWidth,
                this.params.dom.clientHeight
            );
        });

        //若为pc模式
        if (this.params.plat === 'pc') {
            //监听鼠标移动以选中花
            this.params.dom.addEventListener('pointermove', (event) => {
                selectFlower.call(this, event);
            });
        } else {
            //监听点击以选中花
            this.params.dom.addEventListener('pointerdown', (event) => {
                selectFlower.call(this, event);
            });
        }
        this.params.dom.addEventListener('pointerup', () => {
            this.three.viewController.enabled = true;
            if (this.params.plat === 'mobile') {
                this.three.objController.detach()
            }
            renderReal(this);
        })

        this.three.objController.addEventListener(
            'dragging-changed',
            (event) => {
                const objectPosition = event.target.object.position;
                this.tempPositions.push({ x: objectPosition.x, y: objectPosition.y, z: objectPosition.z });
                this.three.viewController.enabled = !event.value;
                if (event.value) {
                    event.target.object.parentObject.clearReal();
                    this.three.status = 'model';
                } else {
                    this.commandManager.execute(new MoveCommand({
                        id: event.target.object.parentObject.id,
                        start: this.tempPositions[0],
                        end: this.tempPositions[1]
                    }, this));
                    this.tempPositions = [];
                }
            }

        );

        //通过控制器拖动模拟花头时更新根部曲线
        this.three.objController.addEventListener('objectChange', (event) => {
            let targetObject = event.target.object;
            targetObject.parentObject.updateModel();
            if (this.listener['flower-choose']) {
                this.listener['flower-choose']({
                    id: targetObject.parentObject.id,
                    height: targetObject.position.y,
                    name: targetObject.parentObject.resource.name,
                    thumb: targetObject.parentObject.resource.thumb,
                    ...targetObject.parentObject.params,
                });
            }

        })

        //视角转动时触发
        this.three.viewController.addEventListener('change', (e) => {
            renderModel(this);
            if (this.listener['camera-change']) {
                let rotateY = THREE.MathUtils.radToDeg(e.target.getAzimuthalAngle()),
                    rotateX = THREE.MathUtils.radToDeg(e.target.getPolarAngle());
                if (rotateY < 0) {
                    rotateY = 180 + (180 + rotateY);
                }
                this.listener['camera-change']({ rotateX, rotateY });
            }
        });

    }

    //初始化物体
    initObject() {

        //初始化剑山
        var shanMesh = new THREE.Mesh(new THREE.CylinderGeometry(40, 42, 5, 60), new THREE.MeshPhysicalMaterial({ color: 0x999999 }));
        shanMesh.position.y = 0;
        var jianMesh = new THREE.Mesh(new THREE.CylinderGeometry(35, 35, 5, 60), new THREE.MeshPhysicalMaterial({ color: 0xD7B763 }));
        jianMesh.position.y = 5;
        var jsContainer = new THREE.Group();
        jsContainer.add(shanMesh);
        jsContainer.add(jianMesh);
        this.three.scene.add(jsContainer);
        this.objects.jsContainer = jsContainer;

        //初始化辅助线
        let supportLine = new THREE.GridHelper(30000, 1000, 0xcecece, 0xd5d5d5);
        supportLine.position.y = -5;
        this.three.scene.add(supportLine);
        this.objects.supportLine = supportLine;

        //初始化光线
        //环境光
        const envLight = new THREE.AmbientLight(0xefefef, 0.7);
        //太阳光
        const sunLight = new THREE.DirectionalLight(0xffffff, 0.4);
        sunLight.position.set(0, 1, 0);
        this.three.scene.add(sunLight);
        var lights = new THREE.Group();
        lights.add(envLight);
        lights.add(sunLight);
        this.three.scene.add(lights);
        this.objects.lights = lights;
    }

    //添加监听器
    addEventListener(method, callback) {
        this.listener[method] = callback;
    }

    //控制剑山
    showJsContainer(show = true) {
        this.objects.jsContainer.visible = show;
    }

    //设置捆扎方式
    setBindingType(type) {
        new SetBindingTypeCommand(type || 'chineseStyle', this).do();
    }

    //设置触点
    setTouchHelper(show) {
        new SetTouchHelperCommand(show || false, this).do();
    }

    //设置捆扎高度
    setBindingY(params) {
        new SetBindingYCommand(params, this).do();
    }

    //设置花头大小
    setSize(params) {
        new SetSizeCommand(params, this).do();
    }

    //旋转
    rotate(params) {
        return new RotateCommand(params, this).do();
    }

    //设置放大倍数
    setZoom(val) {
        new SetZoomCommand(val, this).do();
    }

    //设置角度
    setAngle(params) {
        new SetAngleCommand(params, this).do();
    }

    //清除
    clear(type) {
        new ClearCommand(type, this).do();
    }

    //撤销
    revoke() {
        this.commandManager.revoke()
    }

    //重做
    redo() {
        this.commandManager.redo();
    }

    //添加物体
    addItem(params) {
        this.commandManager.execute(new AddItemCommand(params instanceof Object ? params : { resourceId: params }, this));
    }

    //删除物体
    remove(id) {
        this.commandManager.execute(new ReomveCommand(id, this));
    }

    //拷贝物体
    copy(id) {
        this.commandManager.execute(new CopyCommand(id, this));
    }

    //替换
    replace(params) {
        this.commandManager.execute(new ReplaceCommand(params, this));
    }

    //保存
    save() {
        let y = THREE.MathUtils.radToDeg(this.three.viewController.getAzimuthalAngle()),
            x = THREE.MathUtils.radToDeg(this.three.viewController.getPolarAngle());
        if (y < 0) {
            y = 180 + (180 + y);
        }

        let flowers = [];
        for (let flowerObject of this.objects.flowers) {
            const fPosition = flowerObject.modelMesh.flowerMesh.position;
            flowers.push({
                resourceId: flowerObject.resource.id,
                resourceName: flowerObject.resource.name,
                position: { x: fPosition.x, y: fPosition.y, z: fPosition.z },
                params: flowerObject.params
            })
        }

        let container = '';
        if (this.objects.container) {
            container = {
                resourceId: this.objects.container.resource.id,
                params: this.objects.container.params
            }
        }

        const jsContainer = {
            visible: this.objects.jsContainer.visible,
            size: this.objects.jsContainer.scale.x
        };

        let jsonData = {
            objects: {
                flowers,
                container,
                jsContainer
            },
            angle: {
                x,
                y
            },
            params: {
                render: {
                    bindingType: this.params.render.bindingType
                }
            }
        }

        this.objects.supportLine.visible = false;
        this.render();
        let base64 = new CreateImageCommand({
            width: 500,
            height: 500
        }, this).do();
        this.objects.supportLine.visible = true;

        return {
            jsonData,
            base64
        }
    }

    async load(jsonData) {
        this.params.render.bindingType = jsonData.params.render.bindingType;

        for (let flower of jsonData.objects.flowers) {
            new AddItemCommand(flower, this).do();
        }

        this.objects.jsContainer.visible = jsonData.objects.jsContainer.visible;
        new SetSizeCommand({ id: 'jianshan', value: jsonData.objects.jsContainer.size }, this).do();

        if (jsonData.objects.container) {
            new AddItemCommand(jsonData.objects.container, this).do();
        }
    }

    //渲染真实场景
    renderRealScene() {
        renderReal(this);
    }
}

export default Editor;